import JokeService from "@/services/jokeService";

const store = {
  namespaced: true,
  state: () => ({
    joke: null,
  }),
  mutations: {
    update_joke(state: any, joke: any) {
      state.joke = joke;
    },
  },
  actions: {
    async fetchJoke(context: any) {
      const joke = await JokeService.fetchRandomJoke();
      context.commit("update_joke", joke);
    },
  },
  getters: {
    getJoke: (state: any) => state.joke,
  },
};

export default store;
