import { Comment, Post } from "@/models/travel/Post";
import PostUtils from "@/services/travel/postService";

const initialPost = {
  id: null,
  title: "",
  text: "",
  blurb: "",
  user: null,
  places: [],
  img: "",
  show_itinerary: true,
  start_date: "",
  end_date: "",
  comments: [],
  is_published: true,
  published_date: "",
  createdAt: "",
  updatedAt: "",
};

const store = {
  namespaced: true,
  state: () => ({
    posts: [],
    current_post: initialPost,
  }),
  mutations: {
    update_posts(state: any, posts: Post[]) {
      state.posts = posts;
    },
    update_current_post(state: any, post: Post) {
      state.current_post = post;
    },
  },
  actions: {
    async fetch_all_posts(context: any) {
      const postUtils = new PostUtils();
      const posts = await postUtils.fetchAllPosts(true);
      await context.commit("update_posts", posts);
    },
    async fetch_post(context: any, id: number) {
      const postUtils = new PostUtils();
      const post = await postUtils.fetchPostById(id);
      await context.commit("update_current_post", post);
    },
    async fetch_drafts(context: any) {
      const postUtils = new PostUtils();
      const posts = await postUtils.fetchAllPosts(true);
      await context.commit("update_posts", posts);
    },
    async reset_current_post(context: any) {
      const post = initialPost;
      context.commit("update_current_post", post);
    },
    async create_post(context: any, post: Post) {
      const postUtils = new PostUtils();
      const newPost = await postUtils.createPost(post);
      context.commit("update_current_post", newPost);
      return newPost.id;
    },
    async update_post(context: any, post: Post) {
      const postUtils = new PostUtils();
      const newPost = await postUtils.updatePost(post);
      context.commit("update_current_post", newPost);
    },
    async delete_post(context: any) {
      const postUtils = new PostUtils();
      await postUtils.deletePost(context.state.current_post.id);
    },
  },
  getters: {
    getPosts: (state: any) => state.posts,
    getCurrentPost: (state: any) => state.current_post,
  },
};

export default store;
