<template>
  <HomeHeader v-if="home_header" />
  <DefaultHeader v-else />
  <div id="appContainer">
    <router-view />
    <BottomBar />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  background-color: #f7f9fc !important;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#appContainer {
  margin-bottom: calc(65px + env(safe-area-inset-bottom));
  margin-top: 70px;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<script>
import BottomBar from "@/components/global/bottomNavigation/BottomBar";
import HomeHeader from "@/components/global/HomeHeader";
import DefaultHeader from "@/components/global/DefaultHeader";
export default {
  components: { DefaultHeader, HomeHeader, BottomBar },
  computed: {
    home_header() {
      return this.$route.name === "Home" || this.$route.name === "Login";
    },
  },
};
</script>
