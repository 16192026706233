import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import FontAwesomeIcon from "@/utils/font-awesome-icon";
import VCalendar from "v-calendar";

import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "flag-icon-css/css/flag-icon.min.css";
import "vue-select/dist/vue-select.css";
import "v-calendar/dist/style.css";

import "chartjs-adapter-date-fns";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("QuillEditor", QuillEditor)
  .use(store)
  .use(router)
  .use(VCalendar, {})
  .mount("#app");
