<template>
  <div class="header">
    <div class="back-btn" @click="handleGoBack">
      <font-awesome-icon icon="chevron-left" /> Retour
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultHeader",
  methods: {
    handleGoBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header {
  transition: background-color 0.4s linear;
  background-color: var(--bs-primary);
  height: 60px;
  color: white;
  font-weight: bold;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 15;
}
.back-btn {
  text-align: left;
  padding-top: 18px;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 12px;
  max-width: 80px;
}
</style>
