import { Location } from "@/models/todo/Location";
import LocationService from "@/services/todo/locationService";

const store = {
  namespaced: true,
  state: () => ({
    locations: [],
  }),
  mutations: {
    update_locations(state: any, locations: Location[]) {
      state.locations = locations;
    },
  },
  actions: {
    async fetchLocations(context: any) {
      const locations = await LocationService.fetchLocations();
      context.commit("update_locations", locations);
    },
    async addLocation(context: any, location: Location) {
      const newLocation = await LocationService.addLocation(location);
      context.commit(
        "update_locations",
        context.state.locations.concat([newLocation])
      );
      return newLocation;
    },
  },
  getters: {
    getLocations: (state: any) => state.locations,
  },
};

export default store;
