import { User } from "@/models/User";
import { Location } from "@/models/todo/Location";

export enum ItemFamilies {
  ACTIVITY = "activity",
  MOVIE = "movie",
  RESTAURANT = "restaurant",
  CUSTOM = "custom",
}

export enum ItemBudgets {
  FREE = "free",
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export enum MovieTypes {
  MOVIE = "movie",
  SERIES = "series",
}

export enum MovieStates {
  TODO = "toDo",
  IN_PROGRESS = "inProgress",
  DONE = "done",
}

export const movieStateToColor = {
  [MovieStates.TODO]: "red",
  [MovieStates.IN_PROGRESS]: "orange",
  [MovieStates.DONE]: "green",
};

export const movieStateToText = {
  [MovieStates.TODO]: "À regarder",
  [MovieStates.IN_PROGRESS]: "En cours",
  [MovieStates.DONE]: "Terminé",
};

export const movieTypeToText = {
  [MovieTypes.MOVIE]: "Film",
  [MovieTypes.SERIES]: "Série",
};

export enum MoviePlatforms {
  NETFLIX = "netflix",
  PRIME = "prime",
  DISNEY = "disney",
  APPLE = "apple",
}

export const moviePlatformToText = {
  [MoviePlatforms.NETFLIX]: "Netflix",
  [MoviePlatforms.PRIME]: "Prime Video",
  [MoviePlatforms.DISNEY]: "Disney+",
  [MoviePlatforms.APPLE]: "Apple TV",
};

export const budgetToEuros = {
  [ItemBudgets.FREE]: "",
  [ItemBudgets.LOW]: "€",
  [ItemBudgets.MEDIUM]: "€€",
  [ItemBudgets.HIGH]: "€€€",
};

export interface ItemType {
  id: number;
  author: User;
  family: ItemFamilies;
  name: string;
  description?: string;
  created_at: string;
}

export interface Item {
  id?: number;
  author?: User;
  type?: ItemType;
  location?: Location;
  name: string;
  description?: string;
  budget?: ItemBudgets;
  metadata?: any;
  date_done?: string;
  created_at?: string;
}
