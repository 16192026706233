import { createStore } from "vuex";
import GlobalStore from "./modules/global";
import UserStore from "./modules/user";
import HpMapStore from "./modules/travel/map";
import HpPostStore from "./modules/travel/post";
import TdItemsStore from "./modules/todo/item";
import TdFilterStore from "./modules/todo/filter";
import TdLocationStore from "./modules/todo/location";
import FiEnergyStore from "./modules/finance/energy";
import { RootState } from "@/models/StoreStates";

const modules = {
  global: GlobalStore,
  user: UserStore,
  hp_map: HpMapStore,
  hp_post: HpPostStore,
  td_item: TdItemsStore,
  td_filter: TdFilterStore,
  td_location: TdLocationStore,
  fi_energy: FiEnergyStore,
};

const $store = createStore<RootState>({
  modules,
});

export default $store;
