import RequestUtils from "../requestUtils";
import { Place } from "@/models/travel/Place";
import { Country } from "@/models/travel/Country";

const PREFIX = "/travel";

export default class PlaceService extends RequestUtils {
  fetchAllPlaces(): Promise<Place[]> {
    return this.ax
      .get(`${PREFIX}/places`)
      .then((response: any) => {
        return response.data.all;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  fetchPlaceById(id: number): Promise<Place> {
    return this.ax
      .get(`${PREFIX}/places/${id}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  fetchAllCountries(): Promise<Country[]> {
    return this.ax
      .get(`${PREFIX}/countries`)
      .then((response: any) => {
        return response.data.all;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  addPlace(place: Place): Promise<any> {
    return this.ax
      .post(`${PREFIX}/places`, { place })
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  updatePlace(place: Place): Promise<any> {
    return this.ax
      .put(`${PREFIX}/places/${place.id}`, { place })
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  deletePlace(id: number): Promise<any> {
    return this.ax
      .delete(`${PREFIX}/places/${id}`)
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }
}
