import RequestUtils from "../requestUtils";
import { Post } from "@/models/travel/Post";

const PREFIX = "/travel";

export default class PostService extends RequestUtils {
  fetchAllPosts(is_published: boolean): Promise<Post[]> {
    return this.ax
      .get(`${PREFIX}/posts?is_published=${is_published}`)
      .then((response: any) => {
        return response.data.all;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  fetchPostById(id: number): Promise<Post> {
    return this.ax
      .get(`${PREFIX}/posts/${id}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  createPost(post: Post): Promise<any> {
    return this.ax
      .post(`${PREFIX}/posts`, { post })
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  updatePost(post: Post): Promise<any> {
    return this.ax
      .put(`${PREFIX}/posts/${post.id}`, { post })
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  deletePost(id: number): Promise<any> {
    return this.ax
      .delete(`${PREFIX}/posts/${id}`)
      .then((response: any) => response.data)
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }
}
