import { ItemType, Item, ItemFamilies, ItemBudgets } from "@/models/todo/Item";
import ItemService from "@/services/todo/itemService";

const initialCurrentItem: Item = {
  id: undefined,
  name: "",
  description: "",
  location: undefined,
  type: undefined,
  budget: ItemBudgets.FREE,
  metadata: null,
  date_done: undefined,
  author: undefined,
};

const store = {
  namespaced: true,
  state: () => ({
    items: [],
    currentItem: initialCurrentItem,
    types: [],
  }),
  mutations: {
    update_items(state: any, items: Item[]) {
      state.items = items;
    },
    update_types(state: any, types: ItemType[]) {
      state.types = types;
    },
    update_current_item(state: any, item: Item) {
      state.currentItem = item;
    },
    reset_current_item(state: any) {
      Object.assign(state.currentItem, initialCurrentItem);
    },
  },
  actions: {
    async fetchItems(context: any, family: ItemFamilies) {
      const items = await ItemService.fetchItems(family);
      context.commit("update_items", items);
    },
    async fetchItemById(
      context: any,
      params: { id: number; family: ItemFamilies }
    ) {
      const { id, family } = params;
      const item = await ItemService.fetchItemById(id, family);

      context.commit("update_current_item", item);
    },
    async fetchTypes(context: any, family: ItemFamilies) {
      const types = await ItemService.fetchItemTypes(family);
      context.commit("update_types", types);
    },
    async createNewItem({ dispatch }: any, newItem: Item) {
      await ItemService.createNewItem(newItem);
      dispatch("fetchItems", newItem.type?.family);
    },
    async updateCurrentItem({ dispatch }: any, newItem: Item) {
      await ItemService.updateItem(newItem);
      dispatch("fetchItems", newItem.type?.family);
    },
    async deleteCurrentItem(
      { dispatch }: any,
      params: { family: ItemFamilies; id: number }
    ) {
      await ItemService.deleteItem(params.family, params.id);
      dispatch("fetchItems", params.family);
    },
  },
  getters: {
    getItems: (state: any) => state.items,
    getTypes: (state: any) => state.types,
    getCurrentItem: (state: any) => state.currentItem,
  },
};

export default store;
