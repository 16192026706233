import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faGlobeEurope,
  faClipboardCheck,
  faBook,
  faEuroSign,
  faBars,
  faMapMarkedAlt,
  faMapSigns,
  faHiking,
  faUtensils,
  faPlayCircle,
  faListOl,
  faPlus,
  faFileAlt,
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
  faCircle,
  faCheckCircle,
  faCheck,
  faTimes,
  faTimesCircle,
  faUndoAlt,
  faTrash,
  faPen,
  faSave,
  faCog,
  faFilter,
  faArrowRight,
  faTree,
  faFilm,
  faUserFriends,
  faCalendarAlt,
  faCalendarPlus,
  faChartLine,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faHome);
library.add(faGlobeEurope);
library.add(faClipboardCheck);
library.add(faBook);
library.add(faEuroSign);
library.add(faBars);
library.add(faMapMarkedAlt);
library.add(faMapSigns);
library.add(faHiking);
library.add(faUtensils);
library.add(faPlayCircle);
library.add(faListOl);
library.add(faPlus);
library.add(faFileAlt);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faMapMarkerAlt);
library.add(faCircle);
library.add(faCheckCircle);
library.add(faCheck);
library.add(faTimes);
library.add(faTimesCircle);
library.add(faUndoAlt);
library.add(faTrash);
library.add(faPen);
library.add(faSave);
library.add(faCog);
library.add(faFilter);
library.add(faArrowRight);
library.add(faTree);
library.add(faFilm);
library.add(faUserFriends);
library.add(faCalendarAlt);
library.add(faCalendarPlus);
library.add(faChartLine);
library.add(faBolt);

export default FontAwesomeIcon;
