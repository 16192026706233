const MENU = [
  {
    id: 1,
    icon: "home",
    title: "Home",
    name: "Home",
    navbar: true,
    theme: "#0d6efd",
  },
  {
    id: 2,
    icon: "globe-europe",
    title: "Horizon",
    navbar: true,
    childs: [
      {
        id: 201,
        icon: "map-marked-alt",
        title: "Carte",
        name: "HorizonMap",
        theme: "#17a2b8",
      },
      { id: 202, icon: "book", title: "Journal", name: "HorizonDiary" },
      {
        id: 203,
        icon: "map-signs",
        title: "Endroits",
        name: "HorizonPlaces",
        theme: "#17a2b8",
      },
    ],
  },
  {
    id: 3,
    icon: "clipboard-check",
    title: "ToDo",
    navbar: true,
    childs: [
      { id: 301, icon: "hiking", name: "TodoActivities", title: "Activités" },
      { id: 302, icon: "utensils", name: "TodoRestaurants", title: "Restau" },
      { id: 303, icon: "play-circle", name: "TodoFilms", title: "Films" },
      {
        id: 304,
        icon: "list-ol",
        name: "TodoChecklists",
        title: "Perso",
        disabled: true,
      },
    ],
  },
  {
    id: 4,
    icon: "euro-sign",
    title: "Finances",
    navbar: true,
    childs: [
      {
        id: 401,
        icon: "chart-line",
        name: "FinanceStats",
        title: "Stats",
        disabled: true,
      },
      { id: 402, icon: "bolt", name: "FinanceEnergy", title: "Énergie" },
    ],
  },
  { id: 5, icon: "bars", title: "Menu", navbar: true },
  {
    id: 6,
    icon: "calendar-alt",
    title: "Calendrier",
    name: "Calendar",
    disabled: true,
  },
  { id: 7, icon: "cog", title: "Paramètres", name: "Settings" },
];

export default MENU;
