import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import $store from "@/store";
import UserService from "@/services/userService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { id: 1 },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/Calendar.vue"),
    meta: { id: 6 },
  },
  {
    path: "/calendar/:day",
    name: "CalendarDay",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/CalendarDay.vue"),
    meta: { id: 6 },
  },
  {
    path: "/calendar/:date/new",
    name: "CalendarEventAdd",
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "../views/CalendarEditEvent.vue"
      ),
    meta: { id: 6 },
  },
  {
    path: "/calendar/:date/edit/:id",
    name: "CalendarEventEdit",
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "../views/CalendarEditEvent.vue"
      ),
    meta: { id: 6 },
  },
  {
    path: "/settings",
    name: "Settings",
    meta: { id: 7 },
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  },
  {
    path: "/settings/user",
    name: "UserProfile",
    meta: { id: 7 },
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/UserProfile.vue"),
  },
  {
    path: "/settings/user/password",
    name: "UserPassword",
    meta: { id: 7 },
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/UserPassword.vue"),
  },
  {
    path: "/travel",
    name: "Horizon",
    redirect: "/travel/map",
    component: () =>
      import(/* webpackChunkName: "travel-map" */ "../views/travel/Home.vue"),
    children: [
      {
        path: "map",
        name: "HorizonMap",
        meta: { id: 201 },
        component: () =>
          import(
            /* webpackChunkName: "travel-map" */ "../views/travel/Map.vue"
          ),
      },
      {
        path: "diary",
        name: "HorizonDiary",
        meta: { id: 202 },
        component: () =>
          import(
            /* webpackChunkName: "travel-diary" */ "../views/travel/Diary.vue"
          ),
      },
      {
        path: "diary/:id",
        name: "HorizonPostDetails",
        meta: { id: 202 },
        component: () =>
          import(
            /* webpackChunkName: "travel-diary-details" */ "../views/travel/PostDetails.vue"
          ),
      },
      {
        path: "diary/:id/edit",
        name: "HorizonPostEdit",
        meta: { id: 202 },
        component: () =>
          import(
            /* webpackChunkName: "travel-diary-details" */ "../views/travel/EditPost.vue"
          ),
      },
      {
        path: "diary/new",
        name: "HorizonPostAdd",
        meta: { id: 202 },
        component: () =>
          import(
            /* webpackChunkName: "travel-diary-details" */ "../views/travel/EditPost.vue"
          ),
      },
      {
        path: "places",
        name: "HorizonPlaces",
        meta: { id: 203 },
        component: () =>
          import(
            /* webpackChunkName: "travel-places" */ "../views/travel/Places.vue"
          ),
      },
      {
        path: "places/:id",
        name: "HorizonPlaceDetails",
        meta: { id: 2 },
        component: () =>
          import(
            /* webpackChunkName: "travel-place-details" */ "../views/travel/PlaceDetails.vue"
          ),
      },
      {
        path: "places/new",
        name: "HorizonPlaceAdd",
        meta: { id: 2 },
        component: () =>
          import(
            /* webpackChunkName: "travel-place-details" */ "../views/travel/EditPlace.vue"
          ),
      },
      {
        path: "places/new/location",
        name: "HorizonPlaceAddLocation",
        meta: { id: 2 },
        beforeEnter(to, from, next) {
          if (
            to.name === "HorizonPlaceEditLocation" &&
            !$store.getters["hp_map/getCurrentPlace"].id
          ) {
            next({ name: "HorizonPlaceEdit", params: to.params });
            return;
          }
          next();
        },
        component: () =>
          import(
            /* webpackChunkName: "travel-place-details" */ "../views/travel/EditPlaceLocation.vue"
          ),
      },
      {
        path: "places/:id/edit",
        name: "HorizonPlaceEdit",
        meta: { id: 2 },
        component: () =>
          import(
            /* webpackChunkName: "travel-place-details" */ "../views/travel/EditPlace.vue"
          ),
      },
      {
        path: "places/:id/edit/location",
        name: "HorizonPlaceEditLocation",
        meta: { id: 2 },
        component: () =>
          import(
            /* webpackChunkName: "travel-place-details" */ "../views/travel/EditPlaceLocation.vue"
          ),
      },
    ],
  },
  {
    path: "/todo",
    name: "Todo",
    redirect: "/todo/activities",
    component: () =>
      import(
        /* webpackChunkName: "todo-activities" */ "../views/todo/Home.vue"
      ),
    children: [
      {
        path: "activities",
        name: "TodoActivities",
        meta: { id: 301 },
        component: () =>
          import(
            /* webpackChunkName: "todo-items" */ "../views/todo/ItemsView.vue"
          ),
      },
      {
        path: "activities/new",
        name: "TodoActivityAdd",
        meta: { id: 301 },
        component: () =>
          import(
            /* webpackChunkName: "todo-item-details" */ "../views/todo/EditItemView.vue"
          ),
      },
      {
        path: "activities/:id",
        name: "TodoActivityEdit",
        meta: { id: 301 },
        component: () =>
          import(
            /* webpackChunkName: "todo-item-details" */ "../views/todo/EditItemView.vue"
          ),
      },
      {
        path: "restaurants",
        name: "TodoRestaurants",
        meta: { id: 302 },
        component: () =>
          import(
            /* webpackChunkName: "todo-items" */ "../views/todo/ItemsView.vue"
          ),
      },
      {
        path: "restaurants/new",
        name: "TodoRestaurantAdd",
        meta: { id: 301 },
        component: () =>
          import(
            /* webpackChunkName: "todo-item-details" */ "../views/todo/EditItemView.vue"
          ),
      },
      {
        path: "restaurants/:id",
        name: "TodoRestaurantEdit",
        meta: { id: 301 },
        component: () =>
          import(
            /* webpackChunkName: "todo-item-details" */ "../views/todo/EditItemView.vue"
          ),
      },
      {
        path: "films",
        name: "TodoFilms",
        meta: { id: 303 },
        component: () =>
          import(
            /* webpackChunkName: "todo-items" */ "../views/todo/ItemsView.vue"
          ),
      },
      {
        path: "films/:id",
        name: "TodoFilmEdit",
        meta: { id: 303 },
        component: () =>
          import(
            /* webpackChunkName: "todo-item-details" */ "../views/todo/EditItemView.vue"
          ),
      },
      {
        path: "films/new",
        name: "TodoFilmAdd",
        meta: { id: 303 },
        component: () =>
          import(
            /* webpackChunkName: "todo-item-details" */ "../views/todo/EditItemView.vue"
          ),
      },
      {
        path: "checklists",
        name: "TodoChecklists",
        meta: { id: 304 },
        component: () =>
          import(
            /* webpackChunkName: "todo-checklists" */ "../views/todo/Checklists.vue"
          ),
      },
      {
        path: "checklists/:id",
        name: "TodoChecklistDetail",
        meta: { id: 304 },
        component: () =>
          import(
            /* webpackChunkName: "todo-checklists-details" */ "../views/todo/ChecklistDetail.vue"
          ),
      },
      {
        path: "checklists/:id/edit",
        name: "TodoChecklistEdit",
        meta: { id: 304 },
        component: () =>
          import(
            /* webpackChunkName: "todo-checklists-details" */ "../views/todo/EditChecklist.vue"
          ),
      },
      {
        path: "checklists/add",
        name: "TodoChecklistAdd",
        meta: { id: 304 },
        component: () =>
          import(
            /* webpackChunkName: "todo-checklists-details" */ "../views/todo/EditChecklist.vue"
          ),
      },
      {
        path: "checklists/:id/items/new",
        name: "TodoChecklistItemAdd",
        meta: { id: 304 },
        component: () =>
          import(
            /* webpackChunkName: "todo-checklists-details" */ "../views/todo/EditChecklistItem.vue"
          ),
      },
      {
        path: "checklists/:id/items/:item_id/edit",
        name: "TodoChecklistItemEdit",
        meta: { id: 304 },
        component: () =>
          import(
            /* webpackChunkName: "todo-checklists-details" */ "../views/todo/EditChecklistItem.vue"
          ),
      },
    ],
  },
  {
    path: "/finance",
    name: "Finance",
    redirect: "/finance/stats",
    component: () =>
      import(
        /* webpackChunkName: "finance-stats" */ "../views/finance/Home.vue"
      ),
    children: [
      {
        path: "stats",
        name: "FinanceStats",
        meta: { id: 401 },
        component: () =>
          import(
            /* webpackChunkName: "finance-stats" */ "../views/finance/Stats.vue"
          ),
      },
      {
        path: "energy",
        name: "FinanceEnergy",
        meta: { id: 402 },
        component: () =>
          import(
            /* webpackChunkName: "finance-stats" */ "../views/finance/Energy.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    to.name !== "Login" &&
    UserService.isAuthenticated() &&
    !$store.getters["user/getUser"]
  ) {
    try {
      await $store.dispatch("user/fetchUser");
      if ($store.getters["user/getUser"]) next();
      else next({ name: "Login", params: { from: to.fullPath || "" } });
    } catch (error) {
      next({ name: "Login", params: { from: to.fullPath || "" } });
    }
  } else if (
    to.name === "Login" &&
    UserService.isAuthenticated() &&
    $store.getters["user/getUser"]
  )
    next({ name: "Home" });
  else if (to.name !== "Login" && !UserService.isAuthenticated())
    next({ name: "Login", params: { from: to.fullPath || "" } });
  else next();
});

const beforeEnterProtected = async (to, from, next) => {
  let user = $store.getters["user/getUser"];
  if (!user) {
    await $store.dispatch("user/fetchUser");
    user = $store.getters["user/getUser"];
  }
  if (user.role !== 1) {
    next({ name: "Home" });
  } else next();
};

export default router;
