import RequestUtils from "./requestUtils";
import { User } from "../models/User";
import Cookies from "js-cookie";

class UserService extends RequestUtils {
  login(username: string, password: string): Promise<User> {
    return this.ax
      .post("/login", { username, password })
      .then((response: any) => {
        Cookies.set("authorization", `Bearer ${response.data.token}`, {
          expires: 31536000,
        });
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  fetchAllUsers(): Promise<User[]> {
    return this.ax
      .get("/users")
      .then((response: any) => {
        return response.data.all;
      })
      .catch((err: any) => {
        throw new Error(err.response.data.error);
      });
  }

  fetchUser(): Promise<User> {
    if (Cookies.get("authorization")) {
      return this.ax
        .get("/users/current")
        .then((response: any) => {
          return response.data;
        })
        .catch((err: any) => {
          throw new Error(err.response.data.error);
        });
    }
    return Promise.reject();
  }

  createUser(user: any): Promise<any> {
    return this.ax
      .post("/users/register", user)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  updateProfile(user: any): Promise<User> {
    return this.ax
      .put(`/users/${user.id}`, { user })
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  updatePassword(user_id: number, old_password: string, new_password: string) {
    return this.ax
      .put(`/users/${user_id}/password`, { old_password, new_password })
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  deleteUser(user_id: number) {
    return this.ax
      .delete(`/users/${user_id}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        throw new Error(JSON.stringify(err.response.data));
      });
  }

  logout() {
    Cookies.remove("authorization");
  }

  isAuthenticated() {
    return Cookies.get("authorization");
  }
}

export default new UserService();
