export enum TimeScale {
  YEAR = "year",
  MONTH = "month",
  WEEK = "week",
  DAY = "day",
  HOUR = "hour",
}

export const timeScaleToText = {
  [TimeScale.YEAR]: "Année",
  [TimeScale.MONTH]: "Mois",
  [TimeScale.WEEK]: "Semaine",
  [TimeScale.DAY]: "Jour",
  [TimeScale.HOUR]: "Heure",
};

export interface TimePeriodValue {
  month: number;
  day: number;
}

export interface TimePeriod {
  from: TimePeriodValue;
  to: TimePeriodValue;
}

export interface Filter {
  scale: TimeScale;
  years: number[];
  period: TimePeriod;
}

export interface ConsumptionResponseValue {
  date: string;
  consumption: number;
}

export interface ConsumptionResponseYear {
  year: number;
  values: ConsumptionResponseValue[];
}

export interface ConsumptionResponse {
  all: ConsumptionResponseYear[];
}
