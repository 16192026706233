import { FiEnergyState, RootState } from "@/models/StoreStates";
import {
  ConsumptionResponseYear,
  Filter,
  TimeScale,
} from "@/models/finance/energy";
import { ActionContext, Module } from "vuex";
import EnergyService from "@/services/finance/energyService";

const initialState: FiEnergyState = {
  consumptions: [],
  filters: {
    period: {
      from: {
        month: 1,
        day: 1,
      },
      to: {
        month: 12,
        day: 31,
      },
    },
    scale: TimeScale.MONTH,
    years: [new Date().getFullYear()],
  },
};

const getters = {
  getConsumptions: (state: FiEnergyState) => state.consumptions,
  getFilters: (state: FiEnergyState) => state.filters,
};

const mutations = {
  update_consumptions: (
    state: FiEnergyState,
    consumptions: ConsumptionResponseYear[]
  ) => {
    state.consumptions = consumptions;
  },
  update_filters: (state: FiEnergyState, filters: Filter) => {
    state.filters = filters;
  },
};

const actions = {
  searchConsumptions: async ({
    commit,
    state,
  }: ActionContext<FiEnergyState, RootState>) => {
    const response = await EnergyService.searchConsumptions(state.filters);
    commit("update_consumptions", response);
  },
};

const store: Module<FiEnergyState, RootState> = {
  state: initialState,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default store;
