<template>
  <div class="header">
    <p><font-awesome-icon icon="home" /> JuJo</p>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
};
</script>

<style scoped>
.header {
  background-color: var(--bs-primary);
  height: 60px;
  color: white;
  font-weight: bold;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 15;
}
p {
  padding-top: 15px;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1;
}
</style>
