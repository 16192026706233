import RequestUtils from "@/services/requestUtils";
import { ItemType, Item, ItemFamilies } from "@/models/todo/Item";
import { ApiResponseAll } from "@/models/ApiResponse";
import LocationService from "@/services/todo/locationService";

const PREFIX = "/todo";

class ItemService extends RequestUtils {
  fetchItems = async (family: ItemFamilies): Promise<Item[]> => {
    try {
      const response = await this.ax.get<ApiResponseAll<Item>>(
        `${PREFIX}/items/${family}`
      );
      return response.data.all;
    } catch (err) {
      console.error(err);
      throw new Error(err.response.data.error);
    }
  };

  fetchItemById = async (id: number, family: ItemFamilies): Promise<Item> => {
    try {
      const response = await this.ax.get<Item>(
        `${PREFIX}/items/${family}/${id}`
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw new Error(err.response.data.error);
    }
  };

  fetchItemTypes = async (family: ItemFamilies): Promise<ItemType[]> => {
    try {
      const response = await this.ax.get<ApiResponseAll<ItemType>>(
        `${PREFIX}/items/${family}/types`
      );
      return response.data.all;
    } catch (err) {
      console.error(err);
      throw new Error(err.response.data.error);
    }
  };

  createNewItem = async (item: Item): Promise<void> => {
    try {
      let location = item.location;
      if (item.location?.name && !item.location.id) {
        location = await LocationService.addLocation(item.location);
      }
      await this.ax.post(`${PREFIX}/items/${item.type?.family}`, {
        item: {
          ...item,
          type_id: item.type?.id,
          location_id: location?.id || null,
          location,
        },
      });
    } catch (err) {
      console.error(err);
      throw new Error(err.response.data.error);
    }
  };

  updateItem = async (item: Item): Promise<void> => {
    try {
      let location = item.location;
      if (item.location?.name && !item.location.id) {
        location = await LocationService.addLocation(item.location);
      }
      await this.ax.put(`${PREFIX}/items/${item.type?.family}/${item.id}`, {
        item: {
          ...item,
          type_id: item.type?.id,
          location_id: location?.id || null,
          location,
        },
      });
    } catch (err) {
      console.error(err);
      throw new Error(err.response.data.error);
    }
  };

  deleteItem = async (family: ItemFamilies, id: number): Promise<void> => {
    try {
      await this.ax.delete(`${PREFIX}/items/${family}/${id}`);
    } catch (err) {
      console.error(err);
      throw new Error(err.response.data.error);
    }
  };
}

export default new ItemService();
