import PlaceUtils from "@/services/travel/placeService";
import { Place } from "@/models/travel/Place";
import { MapFilter } from "@/models/travel/MapFilter";
import { Country } from "@/models/travel/Country";

const initialCurrentPlace = {
  id: null,
  latitude: null,
  longitude: null,
  country: null,
  name: "",
  description: "",
  user: null,
  done: false,
  date_done: null,
};

const store = {
  namespaced: true,
  state: () => ({
    places: [],
    filters: {
      visited: true,
      not_visited: true,
      date_before: null,
      date_after: null,
    },
    countries: [],
    current_place: initialCurrentPlace,
  }),
  mutations: {
    update_places(state: any, places: Place[]) {
      state.places = places;
    },
    update_current_place(state: any, place: Place) {
      state.current_place = place;
    },
    update_current_place_longitude(state: any, longitude: number) {
      state.current_place.longitude = longitude;
    },
    update_current_place_latitude(state: any, latitude: number) {
      state.current_place.latitude = latitude;
    },
    update_current_place_country(state: any, country: Country) {
      state.current_place.country = country as Country;
    },
    update_current_place_name(state: any, name: string) {
      state.current_place.name = name;
    },
    update_current_place_description(state: any, description: string) {
      state.current_place.description = description;
    },
    update_filters(state: any, filter: MapFilter) {
      state.filters = filter;
    },
    update_countries(state: any, countries: Country[]) {
      state.countries = countries;
    },
  },
  actions: {
    async fetch_places(context: any) {
      const placeUtils = new PlaceUtils();
      const places = await placeUtils.fetchAllPlaces();
      context.commit("update_places", places);
    },
    async fetch_place(context: any, id: number) {
      const placeUtils = new PlaceUtils();
      const place = await placeUtils.fetchPlaceById(id);
      context.commit("update_current_place", place);
    },
    async reset_current_place(context: any) {
      context.commit("update_current_place", initialCurrentPlace);
    },
    async fetch_countries(context: any) {
      const placeUtils = new PlaceUtils();
      const countries = await placeUtils.fetchAllCountries();
      context.commit("update_countries", countries);
    },
    async add_place(context: any, place: Place) {
      const placeUtils = new PlaceUtils();
      const newPlace = await placeUtils.addPlace(place);
      context.commit("update_current_place", newPlace);
      return newPlace.id;
    },
    async update_place(context: any, place: Place) {
      const placeUtils = new PlaceUtils();
      const newPlace = await placeUtils.updatePlace(place);
      context.commit("update_current_place", newPlace);
    },
    update_current_place_location(context: any, { longitude, latitude }: any) {
      context.commit("update_current_place_longitude", longitude);
      context.commit("update_current_place_latitude", latitude);
    },
    async delete_place(context: any) {
      const placeUtils = new PlaceUtils();
      await placeUtils.deletePlace(context.state.current_place.id);
    },
  },
  getters: {
    getPlaces: (state: any) => state.places,
    getFilters: (state: any) => state.filters,
    getCountries: (state: any) => state.countries,
    getCurrentPlace: (state: any) => state.current_place,
  },
};

export default store;
