const store = {
  namespaced: true,
  state: () => ({
    filter: {
      checked: false,
      budget: [],
      type: [],
      movieState: [],
      moviePlatform: [],
      movieType: [],
    },
  }),
  mutations: {
    reset_filter(state: any) {
      state.filter = {
        checked: false,
        budget: [],
        type: [],
        movieState: [],
        moviePlatform: [],
        movieType: [],
      };
    },
    update_filter(state: any, filter: any) {
      state.filter = filter;
    },
  },
  actions: {},
  getters: {
    getFilter: (state: any) => state.filter,
  },
};

export default store;
