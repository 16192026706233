import RequestUtils from "@/services/requestUtils";
import {
  ConsumptionResponse,
  ConsumptionResponseYear,
  Filter,
} from "@/models/finance/energy";

const PREFIX = "/energy";

class EnergyService extends RequestUtils {
  searchConsumptions = async (
    filters: Filter
  ): Promise<ConsumptionResponseYear[]> => {
    try {
      const response = await this.ax.post<ConsumptionResponse>(
        `${PREFIX}/search`,
        { filters }
      );
      return response.data.all;
    } catch (e: any) {
      throw new Error(e.response.data.error);
    }
  };
}

export default new EnergyService();
