import RequestUtils from "./requestUtils";

class JokeService extends RequestUtils {
  fetchRandomJoke = async () => {
    try {
      const response = await this.ax.get("/jokes");
      return response.data;
    } catch (err: any) {
      throw new Error(err.response.data.error);
    }
  };
}

export default new JokeService();
