import RequestUtils from "@/services/requestUtils";
import { Location } from "@/models/todo/Location";
import { ApiResponseAll } from "@/models/ApiResponse";

const PREFIX = "/todo";

class LocationService extends RequestUtils {
  fetchLocations = async (): Promise<Location[]> => {
    try {
      const response = await this.ax.get<ApiResponseAll<Location>>(
        `${PREFIX}/locations`
      );
      return response.data.all;
    } catch (e: any) {
      throw new Error(e.response.data.error);
    }
  };

  addLocation = async (location: Location): Promise<Location> => {
    try {
      const response = await this.ax.post<Location>(`${PREFIX}/locations`, {
        location,
      });
      return response.data;
    } catch (err: any) {
      throw new Error(err.response.data.error);
    }
  };
}

export default new LocationService();
