import { User } from "@/models/User";
import UserService from "@/services/userService";

const store = {
  namespaced: true,
  state: () => ({
    user: null,
    allUsers: [],
  }),
  mutations: {
    update_all_users(state: any, users: User[]) {
      state.all_users = users;
    },
    update_user(state: any, user: User) {
      state.user = user;
    },
  },
  actions: {
    async login(context: any, params: { username: string; password: string }) {
      const user = await UserService.login(params.username, params.password);
    },
    async fetchAllUsers(context: any) {
      await UserService.fetchAllUsers().then((users: User[]) => {
        context.commit("update_all_users", users);
      });
    },
    async fetchUser(context: any) {
      await UserService.fetchUser().then((user: User) => {
        context.commit("update_user", user);
      });
    },
    async createUser(context: any, newUser: any) {
      await UserService.createUser(newUser);
    },
    async updateProfile(context: any, newUser: any) {
      await UserService.updateProfile(newUser).then((user: User) => {
        context.commit("update_user", user);
      });
    },
    async updatePassword(
      context: any,
      params: { old_password: string; new_password: string }
    ) {
      await UserService.updatePassword(
        context.state.user.id,
        params.old_password,
        params.new_password
      );
    },
    async deleteUser(context: any, user_id: number) {
      await UserService.deleteUser(user_id);
    },
  },
  getters: {
    getUser: (state: any) => state.user,
    getAllUsers: (state: any) => state.allUsers,
  },
};

export default store;
